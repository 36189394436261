import React, { useContext, useState } from "react";
import "./Login.css"; // Import the CSS file
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { userIdContext } from "./App";

function Login({setisAuthenticated}) {  
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailerror, setEmailerror] = useState(false);
  const [emailvalidate, setEmailvalidate] = useState(false);
  const [passerror, setPasserror] = useState(false);  
  const {userId,setuserId}=useContext(userIdContext);

  const apiPath = process.env.REACT_APP_BACKEND_URL;

  const navigate = useNavigate()

  function handleSubmit(e){
    e.preventDefault();

    if(email.length==0) {
      setEmailerror(true);
      setEmailvalidate(false);
    }    
    else {      
      setEmailerror(false);
    }    

    if(password.length==0) {
      setPasserror(true);
    }    
    else {
      setPasserror(false);
    }

    if(email != "" && password != "") {
      if(email.indexOf("@") == -1 ) {
        setEmailvalidate(true);        
      }
      else {        
        setEmailvalidate(false);
        console.log(email + " : " + password);                
      }      
    }
    axios.post(apiPath+'/login',{
      email:email,
      password:password
    }).then((res)=>{
      console.log(res.data);
      setisAuthenticated(res.data.status);
      setuserId(res.data.user_id)
      console.log("userid ",res.data.user_id)
      res.data.status ? navigate('chat') : console.log("error")
  })
    
  }
  
  return (
    <div>
      <div className="headerHolder">
        <div className="paddingLR20">
          <div className="header">
            <img className="img" src="logo.png" alt="" />
            <img className="img" src="logo2.png" alt="" />
          </div>
        </div>
        <div className="login-borderB2L"></div>
      </div>
    
      <div className="loginScreen">
        <div className="login-form">
          <div className="loginTitle">Sign In</div>
          <div className="form">
            <form onSubmit={handleSubmit}>
              <div className="inputBody">
                <div className="form-group">
                  <input type="text" name="uname" placeholder="Email Address" onChange={e=>setEmail(e.target.value)} className="form-control" />
                  {emailerror ? <span style={{color:"red", fontSize:"12px"}}>Email can't be empty</span>: null}
                  {emailvalidate ? <span style={{color:"red", fontSize:"12px"}}>Invalid Email Format</span>: null}
                  {/* {renderErrorMessage("uname")} */}
                </div>
                <div className="form-group" style={{marginBottom: "0px"}}>
                  <input type="password" name="pass" placeholder="Password" onChange={e=>setPassword(e.target.value)} className="form-control" />
                  {passerror ? <span style={{color:"red", fontSize:"12px"}}>Password can't be empty</span>: null}
                  {/* {renderErrorMessage("pass")} */}
                </div>
                {/* {emptyinputs ? <span style={{color:"red", fontSize:"12px"}}>Enter input value</span>: null} */}
              </div>              
              <div className="inputFooter">
                <input type="submit" value="Submit" className="lp-btn-submit" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;