import React, { useState, useEffect, createContext } from "react";
import "./App.css"; // Import the CSS file
import { BrowserRouter as Router,Routes,Route } from "react-router-dom";
import Login from "./Login"
import Chat from "./Chat";
export const userIdContext = createContext();
function App() {
const [isAuthenticated, setisAuthenticated] = useState(false)
const [userId, setuserId] = useState("")
  return (
    <userIdContext.Provider value={{ userId, setuserId }}>
    <Routes>
      {isAuthenticated ? <><Route path="/chat"element={<Chat/>}/></> : <></>}
      <Route path="/" element={<Login isAuthenticated={isAuthenticated} setisAuthenticated={setisAuthenticated}/>}/>
      <Route path="*" element={<Login isAuthenticated={isAuthenticated} setisAuthenticated={setisAuthenticated}/>} />
    </Routes>
    </userIdContext.Provider>
  );
}

export default App;
