import React, { useState, useEffect, useContext } from "react";
import "./App.css"; // Import the CSS file
import axios from "axios";
import Loader from "./Loader";
import { userIdContext } from "./App";

const Chat = () => {
  const [selectedOption, setSelectedOption] = useState("");
  const { userId, setuserId } = useContext(userIdContext)
  const [prevQuestions, setprevQuestions] = useState([]);
  const [prevAnswers, setprevAnswers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [question, setquestion] = useState("");
  // State for storing the input text
  const [inputText, setInputText] = useState("");
  const [options, setOptions] = useState([]);
  const [answer, setAnswer] = useState("");
  const [gptanswers, setgptanswers] = useState([]);
  const [gptquestions, setgptquestions] = useState([]);
  const [selectedTitle, setselectedTitle] = useState("");
  // Options for the dropdown list
  const [selectedBook, setSelectedBook] = useState(null);
  const [booksArray, setbooksArray] = useState([]);
  const apiPath = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    axios.get(apiPath + "/booklist").then((res) => {
      setOptions(res.data);
      setbooksArray(res.data);
      //setSelectedOption(res.data[0]);
      // options.replace("_", " ");
      // console.log("resData: "+res.data[0].bk_author);
    });
  }, []);

  // Handler for changing the selected option
  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
    //console.log("selectedOption : ", selectedOption, " Event Target : ", event.target);
    setAnswer("");
  };

  // Handler for changing the input text
  const handleInputChange = (event) => {
    setInputText(event.target.value);
  };

  const handleClickThumb = (e, id) => {
    //https://stackoverflow.com/questions/20377837/how-to-access-custom-attributes-from-event-object-in-react
    //console.log(e.currentTarget.attributes['title'].value);
    setSelectedOption(e.currentTarget.attributes["title"].value);
    setselectedTitle(e.currentTarget.attributes["book_name"].value);
    setSelectedBook(id);
  };

  const handleSubmit = () => {
    setLoading(true);
    setAnswer("");
    setgptquestions([...gptquestions, inputText]);
    setquestion(inputText);
    axios
      .post(apiPath + "/chatdata", {
        filename: selectedOption,
        query: inputText,
        user_id: 1,
        book_id: selectedBook,
        book_name: selectedOption,
      })
      .then((res) => {
        setgptanswers([...gptanswers, res.data]);
        setAnswer(res.data);
        setLoading(false);
      });

  }

  const handleKeypress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSubmit();
    }
  };

  return (
    <div className="App">
      <div className="headerHolder">
        <div className="paddingLR20">
          <div className="header">
            <img className="img" src="logo.png" alt="" />
            <img className="img" src="logo2.png" alt="" />
          </div>
        </div>
        <img
          src="b2l_border.png"
          className="b2l_mobile_border"
          alt="b2l_border.png"
        />
      </div>
      <div className="paddingLR20">
        <div className="page_container">
          <div className="container bodyContainer">
            <h1 className="lp-orange-title">Chat with the Book</h1>

            <div>
              <div className="thumbnail-container">
                <div className="thumbnailgrid custTable-responsive">
                  {booksArray.map((book) => {
                    /* console.log("BK Title : ", book.bk_title); */
                    return (
                      <div
                        key={book.bk_id}
                        className={`block-bn-pin ${selectedBook === book.bk_id
                            ? "block-bn-pinSelect"
                            : "not-selected"
                          }`}
                        onClick={(e) => handleClickThumb(e, book.bk_id)}
                        title={book.bk_isbn}
                        book_name={book.bk_title}
                      >
                        <div className="block-bn-pin-innerCont">
                          <h2 className="bookTitle" title={book.bk_title}>
                            {book.bk_title}
                          </h2>
                          <div className="pubLogoDiv">
                            <div className="pubLogo">
                              <img
                                src={`images/bookCover/${book.bk_isbn}.jpg`}
                                alt={book.bk_cover}
                                className="logo"
                              />
                            </div>
                          </div>
                          <h3 className="authorName" title={book.bk_author}>
                            {book.bk_author}
                          </h3>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="select-container">
                <label htmlFor="dropdown">Selected book:</label>
                <div className="box-container">
                  <h4 style={{ margin: "0px" }}>{selectedTitle}</h4>
                </div>
              </div>
              <div className="question">
                <textarea
                  placeholder="Ask a question..."
                  id="input"
                  type="text"
                  value={inputText}
                  onChange={handleInputChange}
                  onKeyDown={handleKeypress}
                  className="input"
                />                
              </div>
            </div>

            <button
              disabled={!inputText}
              className="lp-btn-submit"
              onClick={handleSubmit}
            >
              Show Answer
            </button>
            <button
              className="lp-btn-submit"
              style={{ float: "right" }}
              onClick={() => {
                console.log("user id", userId)
                setLoading(true);
                axios
                  .get(apiPath + "/prevdata", {
                    params: { user_id: userId }
                  })
                  .then((res) => {
                    console.log(res.data)
                    res.data.map((item) => {
                      console.log(item)
                      setprevAnswers([...prevAnswers, item.answer]);
                      setprevQuestions([...prevQuestions, item.question])
                      setLoading(false);
                    })

                  });
              }}
            >
              View Previous Questions
            </button>
            <div>&nbsp;</div>
          </div>
          {loading && <Loader />}
          {answer && (
            <div className="output">
              <h4 className="ansTitle">Question: {question}</h4>
              <p className="ansDec">{answer}</p>
            </div>
          )}
          {gptanswers.length > 1 ? (
            <div>
              <h3 className="ansTitle">Previous Answers</h3>
              {gptquestions
                .slice(0, gptquestions.length - 1)
                .map((question, index) => (
                  <div>
                    {console.log("question", question)}
                    <div className="output">
                      <h4 className="ansTitle">Question: {question}</h4>
                      {/* {gptanswers.map((answer) => (
                              <p className="ansDec">{answer}</p>
                            ))} */}
                      <p className="ansDec">{gptanswers[index]}</p>
                    </div>
                  </div>
                ))}
            </div>
          ) : null}
          {prevAnswers.length > 0 ? (
            <div>
              <h3 className="ansTitle">Previous Answers</h3>
              {prevQuestions.map((question, index) => (
                <div>
                  {console.log("question", question)}
                  <div className="output">
                    <h4 className="ansTitle">Question: {question}</h4>
                    {/* {gptanswers.map((answer) => (
                              <p className="ansDec">{answer}</p>
                            ))} */}
                    <p className="ansDec">{prevAnswers[index]}</p>
                  </div>
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Chat;
